export const indonesiaOnlineTerms = (
  <>
    <h5>
      Sebelum melanjutkan, harap membaca dan menyetujui syarat & ketentuan
      berikut untuk Peserta Online:
    </h5>
    {/* <h1 className="">Peserta Online:</h1> */}
    <ul>
      <li>
        Peserta yang tidak mengumpulkan dokumen persyaratan (<em>extended abstract</em>, 
        <em> PowerPoint</em>) setelah dua kali pengingat akan dianggap mengundurkan diri 
        secara otomatis.
      </li>
      <li>
        Peserta wajib menggunakan judul dan <em>extended abstract</em> yang sesuai dengan 
        kategori yang diikuti. (Peserta internasional, wajib menggunakan bahasa Inggris untuk 
        seluruh persyaratan dokumen yang dibutuhkan)
      </li>
      <li>
        Keputusan panitia terkait hal ini bersifat final dan tidak dapat
        diganggu gugat.
      </li>
    </ul>
    <p className="text-align">
      Selain itu, kami informasikan bahwa setiap event akan kami daftarkan ke
      kurasi SIM T Puspresnas.
    </p>
    <p className="text-align">
      Namun, hasil kurasi sepenuhnya merupakan wewenang Puspresnas dan tidak
      dapat diganggu gugat oleh pihak manapun. Oleh sebab itu, kami tidak
      menerima sanggahan maupun komplain terkait hasil kurasi yang telah
      ditetapkan oleh Puspresnas.
    </p>
  </>
);


export const indonesiaOfflineTerms = (
  <>
    <h5>
      Sebelum melanjutkan, harap membaca dan menyetujui syarat & ketentuan
      berikut untuk Peserta Offline:
    </h5>
    <ul>
      <li>
        Peserta yang tidak mengumpulkan berkas persyaratan (<em>extended abstract</em>,
        <em> poster</em>) setelah dua kali pengingat akan dianggap mengundurkan diri
        secara otomatis.
      </li>
      <li>
        Peserta wajib menggunakan judul dan <em>extended abstract</em> yang sesuai dengan
        kategori yang diikuti. (Peserta internasional, wajib menggunakan bahasa
        Inggris untuk seluruh <em>requirement</em>)
      </li>
      <li>
        Peserta diwajibkan untuk membuat poster berukuran A0, membawa produk,
        dan juga <em>paper</em>/<em>extended abstract</em> (dalam bentuk <em>hard copy</em>) pada saat sesi
        penjurian.
      </li>
      <li>
        Pemberian penghargaan kepada peserta akan dilakukan sesuai dengan urutan
        yang telah ditetapkan dalam susunan acara (peserta tidak diperkenankan
        untuk meminta <em>award</em> sebelum gilirannya).
      </li>
      <li>
        Peserta diwajibkan untuk mengikuti seluruh rangkaian kegiatan sesuai
        dengan jadwal yang telah disusun oleh panitia penyelenggara.
      </li>
      <li>
        Bagi peserta offline yang berhak atas hadiah <em>Best Booth</em>, <em>Best Poster</em>,
        <em> Best Presentation</em>, <em>Best Project</em>, uang tunai, <em>Semi Grand Award</em>, dan 
        <em>Grand Award</em> namun tidak dapat hadir pada acara penyerahan hadiah, maka hadiah
        yang bersangkutan dinyatakan hangus.
      </li>
      <li>
        Keputusan panitia terkait hal ini bersifat final dan tidak dapat
        diganggu gugat.
      </li>
    </ul>
    <p className="text-align">
      Selain itu, kami informasikan bahwa setiap event akan kami daftarkan ke
      kurasi SIM T Puspresnas.
    </p>
    <p className="text-align">
      Namun, hasil kurasi sepenuhnya merupakan wewenang Puspresnas dan tidak
      dapat diganggu gugat oleh pihak manapun. Oleh sebab itu, kami tidak
      menerima sanggahan maupun komplain terkait hasil kurasi yang telah
      ditetapkan oleh Puspresnas.
    </p>
  </>
);

export const internationalOnlineTerms = (
  <>
    <h3 className="text-2xl">Online Participants:</h3>
    <li>
      Participants who do not submit the required documents (extended abstract,
      PowerPoint) after two reminders will be considered to have resigned
      automatically.
    </li>
    <li>
      Participants must use the title and extended abstract in accordance with
      the category they are participating in. (International participants must
      use English for all required documents)
    </li>
    <li>
      The committee&apos;s decision regarding this matter is final and cannot be
      contested.
    </li>
  </>
);

export const internationalOfflineTerms = (
  <>
    <h3 className="text-2xl">Offline Participants:</h3>
    <li>
      Participants who do not submit the required files (extended abstract,
      poster) after two reminders will be considered automatically resigned.
    </li>
    <li>
      Participants must use titles and extended abstracts that are in accordance
      with the category they are participating in. (International participants
      must use English for all requirements)
    </li>
    <li>
      Participants are required to make an A0-sized poster, bring the product
      and also the paper/extended abstract (in hard copy) during the judging
      session.
    </li>
    <li>
      The awarding of awards to participants will be carried out in accordance
      with the order set out in the schedule of events (participants are not
      allowed to request awards before their turn).
    </li>
    <li>
      Participants are required to follow the entire series of activities in
      accordance with the schedule prepared by the organizing committee.
    </li>
    <li>
      For offline participants who are entitled to the Best booth, Best Poster,
      Best Presentation, Best Project, cash, semi grand award, and grand award
      prizes but cannot attend the prize distribution ceremony, the prizes
      concerned will be declared forfeited.
    </li>
    <li>
      The committee&apos;s decision regarding this matter is final and cannot be
      contested.
    </li>
  </>
);
